/* eslint-disable no-plusplus, no-new */

import './style.scss';

/**
 * Won't register hidden inputs
 *
 * @param {string} name
 * @return {Map<HTMLLabelElement, HTMLInputElement>[]}
 */
const registerCheckios = (name) => {
  const checkios = document.querySelectorAll(`[name="${name}"]`);

  const recheck = (map) => {
    [...map.entries()].forEach((entry) => {
      entry[0].classList.toggle('active', entry[1].checked);
    });
  };

  const allCheckios = [...checkios].reduce((map, input) => {
    const label = input.parentNode;
    if (label.nodeName !== 'LABEL') {
      throw Error('Parent is not a label');
    }

    if (!label.classList.contains('checkio')) {
      label.classList.add('checkio');

      label.addEventListener('change', (event) => {
        recheck(allCheckios);
      });
    }

    return map.set(label, input);
  }, new Map());

  recheck(allCheckios);

  return allCheckios;
};

class Slides {
  constructor(container) {
    this.container = container;

    if (!this.container) {
      return;
    }

    this.currentSlide = 0;
    this.prevBtn = this.container.querySelector('[data-toggle="prev-slide"]');
    this.nextBtn = this.container.querySelector('[data-toggle="next-slide"]');
    this.elements = [...this.container.querySelectorAll('[data-slide]')];
    this.count = this.elements.length - 1;

    this.doIt();

    if (this.prevBtn) {
      this.prevBtn.addEventListener('click', (e) => {
        if (this.currentSlide > 0) {
          this.currentSlide--;
          this.doIt();
        }
      });
    }

    if (this.nextBtn) {
      this.nextBtn.addEventListener('click', (e) => {
        if (this.currentSlide < this.count) {
          this.currentSlide++;
          this.doIt();
        }
      });
    }
  }

  getSlide(id) {
    return this.container.querySelector(`[data-slide="${id}"]`);
  }

  doIt() {
    this.elements.forEach(element => element.classList.add('hidden'));
    this.elements[this.currentSlide].classList.remove('hidden');

    registerCheckios('date');

    const tabs = Array.from(document.querySelectorAll('[data-init="tabs"]'));

    tabs.forEach(t => new Tabs(t));

    this.nextBtn.classList.remove('super-disabled');
    this.prevBtn.classList.remove('super-disabled');

    if (this.currentSlide === 0) {
      this.prevBtn.classList.add('super-disabled');
      this.nextBtn.classList.remove('super-disabled');
    }

    if (this.currentSlide >= this.elements.length - 1) {
      this.nextBtn.classList.add('super-disabled');
      this.prevBtn.classList.remove('super-disabled');
    }
  }
}

class Tabs {
  constructor(container) {
    this.container = container;

    if (!this.container) {
      return;
    }

    this.navs = [...this.container.querySelectorAll('[data-toggle="tab"]')];
    this.elements = [...this.container.querySelectorAll('[data-tab]')];

    this.doIt();

    this.navs.forEach((nav) => {
      nav.addEventListener('change', (e) => {
        this.doIt();
      });
    });

    registerCheckios('date');
  }

  getTab(id) {
    return this.container.querySelector(`[data-tab="${id}"]`);
  }

  doIt() {
    const isSomethingChecked = this.navs.some(nav => nav.checked);
    const tabContent = this.container.querySelector('.tab-content');

    tabContent.classList.toggle('hidden', !isSomethingChecked);

    if (isSomethingChecked) {
      this.navs.forEach((nav) => {
        const target = this.getTab(nav.dataset.target);
        target.classList.toggle('hidden', !nav.checked);
      });
      registerCheckios('start_time');
    }
  }
}

const slides = document.querySelector('[data-init="slides"]');
new Slides(slides);
